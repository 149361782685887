<template>
  <div>
    <!-- @file-complete="fileComplete"
          @complete="complete" -->

    <div class="card">
      <div class="card-body">
        <div class="uploader uploader-example">
          <div class="uploader-drop">
            <div class="row">
              <div class="col-sm-4">
                <div role="group" class="form-group">
                  <label for="uid-hjrt2j5ylch" class=""> 月份 </label>
                  <select id="uid-hjrt2j5ylch" class="form-control">
                    <option data-key="0" value="月帳單">
                      請選擇
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <p>檔案上傳</p>
            <label class="uploader-btn">
              Select Files
              <input
                type="file"
                multiple="multiple"
                id="file"
                ref="file"
                v-on:change="handleFileUpload()"
                style="visibility: hidden; position: absolute; width: 1px; height: 1px;"
              />
            </label>
            <!-- <button v-on:click="submitFile()">Submit</button> -->
          </div>
          <div class="uploader-list"><ul></ul></div>
        </div>
      </div>
      <footer class="card-footer"></footer>
    </div>
    <!-- 共用錯誤彈窗 -->
    <Modal></Modal>
  </div>
</template>

<script>
import Modal from '../Modal.vue'
import Global from '../../Global'
import axios from 'axios'
import md5 from 'js-md5'

export default {
  data() {
    return {
      file: '',
    }
  },
  components: {
    Modal,
  },
  mounted() {},
  methods: {
    // submitFile() {
    //   console.log('hi')
    //   let formData = new FormData()
    //   let md5_key = Global.$API_MD5_KEY
    //   formData.append('file', this.file)

    //   let jsonMsg = JSON.stringify({})
    //   let params = secret.Encrypt(jsonMsg)
    //   let key = md5(params + md5_key)
    //   formData.append('params', params)
    //   formData.append('key', key)
    //   console.log('pk', params, key)
    //   console.log('submit', formData)
    //   axios
    //     .post(Global.$API_URL + 'api/billing/import', formData, {
    //       headers: {
    //         'Accept-Language': localStorage.getItem('LOCALE'),
    //         Authorization: 'Bearer ' + sessionStorage.getItem('token'),
    //       },
    //     })
    //     .then(function() {
    //       console.log('SUCCESS!!')
    //     })
    //     .catch(function() {
    //       console.log('FAILURE!!')
    //     })
    // },
    handleFileUpload() {
      this.file = this.$refs.file.files[0]
      //   console.log('handle file', this.file)
      this.$store.dispatch('actionBillingImport', this.file)
    },
  },
}
</script>

<style></style>
